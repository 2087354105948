<template>
  <v-app class="transparent">
    <v-container fluid>
      <base-v-component heading="Add User" />
      <base-material-card
        icon="mdi-clipboard-text"
        title="Add User"
        class="px-5 py-3"
      >
        <user-form />
      </base-material-card>
    </v-container>
  </v-app>
</template>
<script>
import userFrom from './userForm.vue'

export default {
  name: "AddUser",
  components: {
    'user-form': userFrom
  },
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/',
        },
        {
          text: 'Users',
          disabled: false,
          href: '/users',
        }, {
          text: 'Add User',
          disabled: true,
          href: '#',
        }
      ]
    }
  }
}
</script>
